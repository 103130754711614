import React from "react"

//Styled components imports
import Wrapper from "./Wrapper"
import FourZeroFour from "./FourZeroFour"
import ImageWrapper from "./ImageWrapper"
import Text from "./Text"
import Link from "./Link"

import haresInTheForest from "../../images/hares-in-the-forest.png"

const NotFound = () => {
  return (
    <Wrapper>
      <FourZeroFour>404</FourZeroFour>
      <ImageWrapper>
        <img src={haresInTheForest} alt="Zající v lese" />
      </ImageWrapper>
      <Text>Nevíme už co to bylo, něco se však pokazilo.</Text>
      <Text>
        Nečekejte na pozvánku, přejděte na <Link to="/">hlavní stránku</Link>.
      </Text>
    </Wrapper>
  )
}

export default NotFound
