import React from "react"

import { ThemeProvider } from "styled-components"
import GlobalStyle from "../utils/styled-components/globalStyles"
import { theme } from "../utils/styled-components/theme"

//React component import
import NotFound from "../components/NotFound"

const NotFoundPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <NotFound />
    </ThemeProvider>
  )
}

export default NotFoundPage
