import styled from "styled-components"

const ImageWrapper = styled.div`
  max-width: 520px;
  margin-top: -110px;
  margin-bottom: 16px;

  @media (${props => props.theme.mediaQueries.mobile}) {
    margin-top: -160px;
  }

  @media (${props => props.theme.mediaQueries.tablet}) {
    margin-top: -215px;
  }
`

export default ImageWrapper
