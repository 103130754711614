import styled from "styled-components"

const FourZeroFour = styled.div`
  font-size: 135px;
  font-weight: 600;
  margin-top: -32px;
  color: ${props => props.theme.colors.main};

  @media (${props => props.theme.mediaQueries.mobile}) {
    font-size: 180px;
  }

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 230px;
  }

  @media (${props => props.theme.mediaQueries.desktop}) {
    font-size: 260px;
  }
`

export default FourZeroFour
