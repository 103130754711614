import styled from "styled-components"

const Text = styled.p`
  font-size: 28px;
  max-width: 520px;
  text-align: center;
  margin-bottom: 24px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 32px;
  }
`

export default Text
