import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"

const Link = styled(GatsbyLink)`
  color: ${props => props.theme.colors.text};
  font-weight: 600;

  &:hover  {
    color: ${props => props.theme.colors.main};
  }
`

export default Link
